//
// Pulse
//

// Base
.kt-pulse {
    position: relative;

    .kt-pulse__ring {
        display: block;
        border-radius: 40px;
        height: 40px;
        width: 40px;
        position: absolute;
        animation: kt-pulse 3.5s ease-out;
        animation-iteration-count: infinite;
        opacity: 0;
        border-width: 3px;
        border-style: solid;
        border-color: kt-base-color(grey, 4);

        @include kt-hack-ie {
            top: 2px;
            left: 2px;
        }
    }

    // State Colors
    @each $name, $color in $kt-state-colors {
        &.kt-pulse--#{$name} {
            .kt-pulse__ring {
                //background-color: rgba(kt-get($color, base), 0.8);
                border-color: rgba(kt-get($color, base), 0.8);
            }
        }
    }
}

@keyframes kt-pulse {
    0% {
        -webkit-transform: scale(0.1, 0.1);
        opacity: 0;
    }
    60% {
        -webkit-transform: scale(0.1, 0.1);
        opacity: 0;
    }
    65% {
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1.2, 1.2);
        opacity: 0;
    }
}
