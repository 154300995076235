// load base demo styles. for demo purpose, the style will be loaded here
// for production, include the demo base style in /src/index.html file to load the css earlier
.kt-grid--animateContent {
    transform: translate3d(0, -5%, 0);
    opacity: 0;
    position: static;
    &-finished {
        transform: translate3d(0, 0%, 0);
        transition: 0.2s ease-in-out;
        opacity: 1;
    }
}
