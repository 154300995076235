//
// CKEditor
//

// Base
.ck-target {
    display: none;
}

.ck-toolbar {
    border-radius: $kt-border-radius !important;
}

.ck-content {
    min-height: 200px;
    border-radius: $kt-border-radius !important;

    &.ck-focused {
        border-color: kt-state-color(brand, base) !important;
        box-shadow: none !important;
    }
}

// CKEditor Classic & Document
.ck-editor {
    .ck-toolbar {
        border-top-left-radius: $kt-border-radius !important;
        border-top-right-radius: $kt-border-radius !important;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
    .ck-content {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
        border-bottom-left-radius: $kt-border-radius !important;
        border-bottom-right-radius: $kt-border-radius !important;
    }
}

// CKEditor Inline / Balloon / Balloon Block
.ck-body .ck-balloon-panel {
    .ck-content {
        min-height: 200px;
        border-color: transparent !important;

        &.ck-focused {
            border-color: kt-state-color(brand, base) !important;
        }
    }

    &.ck-toolbar-container,
    .ck-toolbar {
        border-radius: $kt-border-radius !important;
    }
}
