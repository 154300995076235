//
// Scroll
//

// Base
.kt-scroll {
    position: relative;
    overflow: hidden;

    &.kt-scroll--pull {
        padding-right: 12px;
        margin-right: -12px;

        .ps__rail-y {
            right: -2px;
        }
    }
}

// Skin
@include kt-perfect-scrollbar-ver-size($kt-custom-scroll-size);
@include kt-perfect-scrollbar-hor-size($kt-custom-scroll-size);

@include kt-perfect-scrollbar-skin($kt-scroll-color);
