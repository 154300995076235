//
// Brand Dark Skin
//

// Include global config
@import "../../../../config";

// Include layout config
@import "../../config";

// Include header config
@import "../../header/config";

// Include aside config
@import "../../aside/config";

// Desktop mode
@include kt-desktop {
    .kt-aside__brand {
        background-color: #1a1a27;
        box-shadow: none;

        .kt-aside__brand-tools {
            //@include kt-burger-icon-skin(kt-aside__brand-aside-toggler, (default: #484f66, hover: #ffffff, active: #ffffff) );

            .kt-aside__brand-aside-toggler {
                svg {
                    @include kt-svg-icon-color(#494b74);
                }

                &:hover {
                    svg {
                        @include kt-svg-icon-color(kt-brand-color());
                    }
                }
            }
        }
    }
}

// Tablet and mobile mode
@include kt-tablet-and-mobile {
    .kt-header-mobile {
        background-color: #1a1a27;
        box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.1);

        .kt-header__topbar--mobile-on & {
            box-shadow: none;
        }

        // Toolbar
        .kt-header-mobile__toolbar {
            @include kt-burger-icon-skin(
                kt-header-mobile__toggler,
                (
                    default: #484f66,
                    hover: #ffffff,
                    active: #ffffff
                )
            );

            .kt-header-mobile__topbar-toggler {
                i {
                    color: #484f66;
                }

                &.kt-header-mobile__topbar-toggler--active,
                &:hover {
                    i {
                        color: #ffffff;
                    }
                }
            }
        }
    }
}
