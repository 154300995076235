//
// Custom Example 1 Style
// Here you can put your custom sass code, use global SASS mixings and variables to override the theme or add new styles according to your requirements.
// Most of the changes can be achieved via global or layout level sass variable changes.
//

.my-custom-style {
    padding: 1rem 0.5rem;
    color: kt-brand-color();

    .my-custom-element {
        margin-left: 1rem;
    }
}

@include kt-tablet-and-mobile {
    .my-custom-style {
        padding: 0.5rem 0.25rem;

        .my-custom-element {
            margin-left: 0.5rem;
        }
    }
}

#remotePlace:-webkit-full-screen {
    width: 700px !important;
}

#remotePlace:fullscreen {
    width: 700px !important;
}

video::-webkit-media-controls {
    display: none !important;
}

video::-webkit-media-controls-enclosure {
    display: none !important;
}

::-webkit-scrollbar-thumb {
    background: #93a3a8;
    border-radius: 4px;
}

::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

#chatBody::-webkit-scrollbar-thumb {
    background: #93a3a8;
    border-radius: 4px;
}

#chatBody::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

.mosaicRemoteContainer {
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 100%;
    position: absolute;

    .mosaicVideoPlace {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 2px;

        // .mosaicVideoContainer {
        //     display: block;
        //     width: 100%;
        //     position: relative;
        //     background: black;

        & .hoverPlace {
            display: none;
        }

        .displayName {
            position: absolute;
            color: white;
            z-index: 2;
            transform: translateY(50%);
            left: 0;
            text-align: center;
            right: 0;
        }

        .mosaicVideoContain {
            padding-top: 56.25%;
            position: relative;
            display: block;
            height: 100%;
        }
        // }
    }
}

.displayName {
    position: absolute;
    color: white;
    // z-index: 2;
    // transform: translateY(50%);
    left: 0;
    text-align: center;
    right: 0;
    bottom: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-left: 5px;
    margin-right: 5px;
}

.mosaicVideoContainer:hover {
    .mosaicVideoContain {
        opacity: 0.6;
    }
}

.hoverPlace {
    width: 100%;
    background: black;
    // display: none;
    position: relative;
    border: solid 2px;
    border-radius: 4px;
    padding-top: 100%;
    float: left;

    .hoverAvatar {
        position: absolute;
        bottom: 0;
        height: 20px;
        width: 100%;
    }
}

.mosaicVideoContainer {
    .allowBtn {
        display: none;
    }

    &:hover {
        .allowBtn {
            display: block;
            position: absolute;
            bottom: -22px;
            // transform: translateY(50%);
            z-index: 3;
            right: 0;
            left: 0;
            margin-right: auto;
            margin-left: auto;
        }
    }
}

.kt-chat__username {
    color: #fbfb18 !important;
}

.list-unstyled {
    margin-bottom: 0;
}

#eventPlace {
    .MuiSnackbar-root {
        position: absolute;
    }
}
